import React, { memo } from "react";

import PanelHole, { PanelHoleType } from "../PanelHole/PanelHole";

import "./PanelEdge.scss";

export interface PanelEdgeProps {
  backgroundColour: string;
}


function PanelEdge({
  backgroundColour
}: PanelEdgeProps) {
  const solidColourDiv = (
    <div
      style={
        {
          backgroundColor: backgroundColour
        }
      }
    >
    </div>
  );


  return (
    <>
      <div
        className="panel-edge_top-margin"
        style={
          {
            backgroundColor: backgroundColour
          }
        }
      >
      </div>

      <div
        className="panel-edge"
      >
        {solidColourDiv}

        <div>
          <PanelHole type={PanelHoleType.CIRCLE} backgroundColour={backgroundColour} />
        </div>

        <div className="panel-edge_desktop-hole">
          <PanelHole type={PanelHoleType.CIRCLE} backgroundColour={backgroundColour} />
        </div>

        {solidColourDiv}

        <div>
          <PanelHole type={PanelHoleType.BAR} backgroundColour={backgroundColour} />
        </div>

        {solidColourDiv}

        <div className="panel-edge_desktop-hole">
          <PanelHole type={PanelHoleType.CIRCLE} backgroundColour={backgroundColour} />
        </div>

        <div>
          <PanelHole type={PanelHoleType.CIRCLE} backgroundColour={backgroundColour} />
        </div>

        {solidColourDiv}
      </div>
    </>
  );
}

export default memo(PanelEdge);
