import React, { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

import VideoPlayer from "../../VideoPlayer/VideoPlayer";

import "./StageTemplate.scss";
import { MQ_MOBILE } from "../../../utility/constants";

export interface StageTemplateProps {
  title: ReactNode;
  children: ReactNode;
  video?: string | null;
}


function StageTemplate({
  title,
  children,
  video = null
}: StageTemplateProps) {
  const isMobile = useMediaQuery(MQ_MOBILE);


  return (
    <>
      {
        !isMobile && (
          <div className="stage-template_content">
            <div className="stage-template_text">
              <div>
                {title}
              </div>

              <div>
                <div>
                  {children}
                </div>
              </div>
            </div>

            <div>
              {
                video && <VideoPlayer source={`video/${video}`} />
              }
            </div>
          </div>
        )
      }

      {
        isMobile && (
          <div className="stage-template_content-mobile">
            <div>
              {title}
            </div>

            <div>
              {
                video && <VideoPlayer source={`video/${video}`} />
              }
            </div>

            <div>
              {children}
            </div>
          </div>
        )
      }
    </>
  )
}

export default StageTemplate;
