import React, { forwardRef, RefObject, ReactNode } from "react";

import Panel from "../components/Panel/Panel";

export interface CreatePanelParams {
  /**
   * Colour of panel background.
   */
  backgroundColour: string;
  /**
   * Elements to render within the panel.
   */
  children: ReactNode;
  /**
   * An optional function that can be called by e.g., an event handler located
   * within a panel's child elements. In the component where the `createPanel`
   * function is called the callback will have to be referred to by first
   * declaring a variable `x` equal to `createRef<any>()` then calling the
   * callback using the syntax `x.current.callbackFn`, e.g., for an
   * `onClick`event handler: `<... onClick={(event: MouseEvent) =>
   * aRef.current.callbackFn(event)}>` where `aRef` is the variable equal to
   * `createRef<any>()`. Use the `output` prop on the component created with
   * `createPanel()` to access output values from the callback.
   */
  callback?: Function;
  /**
   * The ref to the `Panel` component itself. This is only required if
   * `callback` has been specified as earlier. If the variable declared and
   * equal to `createRef<any>()` is, say, `x` then make `panelRef` equal to `x`
   * when using `createPanel()`.
   */
  panelRef?: RefObject<any> | null;
}

export interface CreatePanelProps {
  output?: any;
}


/**
 * Creates a `Panel` component with specifications and an optional callback.
 *
 * See individual parameters for more information.
 *
 * @returns {React.ForwardRefExoticComponent<React.PropsWithoutRef<CreatePanelProps> & React.RefAttributes<HTMLDivElement>>}
 */
const createPanel = ({
  backgroundColour,
  children,
  callback,
  panelRef = null
}: CreatePanelParams) => forwardRef<HTMLDivElement, CreatePanelProps>(({ output }: CreatePanelProps, ref) => {
  const getOutput = (value: any) => output(value);


  return (
    <>
      <Panel
        backgroundColour={backgroundColour}
        callback={callback}
        output={getOutput}
        ref={ref}
        panelRef={panelRef}
      >
        {children}
      </Panel>
    </>
  )
});

export default createPanel;
