import { MediaQueryAllQueryable } from "react-responsive";

export const BRAND_GREY = "#ebeae0";
export const BRAND_WHITE = "white";
export const BRAND_BLACK = "black";

export const MQ_MOBILE: MediaQueryAllQueryable = {
  maxWidth: 640
};
export const MQ_MOBILE_LANDSCAPE: MediaQueryAllQueryable = {
  screen: true,
  orientation: "landscape",
  minDeviceWidth: 768,
  maxDeviceWidth: 1280,
  maxWidth: 1280
}
