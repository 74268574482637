//noinspection HtmlUnknownTarget

import React, { createRef, MouseEvent } from "react";

import createPanel from "../../utility/createPanel";
import { BRAND_GREY } from "../../utility/constants";

import "./Contact.scss";


const panelRef = createRef<any>();

const PrivacyPolicyLink = () => (
  <button className="contact_privacy-link" onClick={(event: MouseEvent) => panelRef.current.callbackFn(event)}>
    Privatumo Politika
  </button>
);


const Contact = createPanel({
  backgroundColour: BRAND_GREY,
  children: (
    <div className="contact">
      <div className="contact_content">
        <div>
          UAB „NEKILNOJAMOJO TURTO<br />
          PROFESIONALAI”<br />
          Laisvės Al. 5-2<br />
          LT-44237, Kaunas, Lietuva<br />
          <br />
          Tel. +370 685 25155<br />
          El.p. <a href="mailto:info@nt-pro.lt" title="Email">info@nt-pro.lt</a>
          <br />
          <br />
          <div className="_desktop">
            <PrivacyPolicyLink />
          </div>
        </div>

        <div>
          <div className="contact_banking">
            ĮM. KODAS 303381602<br />
            PVM M. KODAS LT100008834012<br />
            A/S LT14 7044 0600 0795 8405<br />
            AB „SEB BANKAS”
          </div>

          <div className="_mobile">
            <br />
            <br />
            <PrivacyPolicyLink />
          </div>
        </div>
      </div>
    </div>
  ),
  callback: (event: MouseEvent) => {
    event.preventDefault();

    panelRef.current.output();
  },
  panelRef
});

export default Contact;
