import React, { memo } from "react";

import createStage from "../../../utility/createStage";
import StageTemplate from "../StageTemplate/StageTemplate";


const StageUse = createStage(
  <>
    <StageTemplate
      title={
        <>
          V. Statinio<br />
          eksploatavimas
        </>
      }
      video="statinio-eksploatavimas.mp4"
    >
      <ul>
        <li>Statinio naudojimo priežiūros organizavimas</li>
        <li>Savininkų bendrijų steigimas, jungtinės veiklos sutarčių rengimas</li>
        <li>Statinio (jo dalies) nuomos (pardavimo) sutartys</li>
        <li>Garantinės priežiūros klausimai</li>
      </ul>
    </StageTemplate>
  </>
);

export default memo(StageUse);
