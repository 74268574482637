import React, { forwardRef, ReactNode } from "react";

import "../components/Stages/Stages.scss";


function createStage(children: ReactNode) {
  return forwardRef<HTMLDivElement>((props, ref) => (
    <div className="stage" ref={ref}>
      {children}
    </div>
  ));
}

export default createStage;
