import React, { useState, useRef, useEffect, ChangeEvent, CSSProperties } from "react";

import { BRAND_BLACK, BRAND_WHITE } from "../../../utility/constants";
import "./MenuButton.scss";

export interface MenuButtonProps {
  active: boolean;
  menuActive: (active: boolean) => void;
}


function MenuButton({
  active,
  menuActive
}: MenuButtonProps) {
  const [isActive, setIsActive] = useState(false);
  const spanStyle: CSSProperties = {
    backgroundColor: isActive ? BRAND_BLACK : BRAND_WHITE
  }
  const checkboxElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsActive(active);

    if(!active) {
      if(checkboxElement && checkboxElement.current) {
        checkboxElement.current.checked = false;
      }
    }
  }, [active]);


  const changeActivity = (activity: boolean) => {
    setIsActive(activity);
    menuActive(activity);
  }


  return (
    <div className="menu-button">
      <div
        className="menu-button_icon"
        style={
          {
            backgroundColor: isActive ? BRAND_WHITE : BRAND_BLACK
          }
        }
      >
        <input className="menu-button_checkbox" type="checkbox" onChange={(event: ChangeEvent<HTMLInputElement>) => changeActivity(event.target.checked)} ref={checkboxElement} />

        <div>
          <span style={spanStyle}></span>
          <span style={spanStyle}></span>
        </div>
      </div>
    </div>
  );
}

export default MenuButton;
