export const holeCircle = (fill: string) =>  (
  <>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70 70"
    >
      <g>
        <g>
          <image
            style={
              {
                overflow: "visible",
                opacity: 0.16
              }
            }
            width="73"
            height="73"
            href="images/hole-circle-shadow.png"
            transform="matrix(1 0 0 1 -1.8689 1.1058)"
          >
          </image>

          <g>
            <path
              className="st0"
              fill={fill}
              d="M17.1,17v36h36V17H17.1z M35.1,51c-8.8,0-16-7.2-16-16c0-8.8,7.2-16,16-16c8.8,0,16,7.2,16,16 C51.1,43.8,43.9,51,35.1,51z"
            />
          </g>
        </g>

        <g>
          <g>
            <path
              className="st0"
              fill={fill}
              d="M0,0v70h70V0H0z M35.1,51c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S43.9,51,35.1,51z"
            />
          </g>
        </g>
      </g>
    </svg>
  </>
);

export const holeBar = (fill: string) => (
  <>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 340 70"
    >
      <g>
        <g>
          <image
            style={
              {
                overflow: "visible",
                opacity: 0.16
              }
            }
            width="341"
            height="73"
            href="images/hole-bar-shadow.png"
            transform="matrix(1 0 0 1 -0.1788 1.7285)"
          >
          </image>

          <g>
            <path
              className="st0"
              fill={fill}
              d="M17.9,16.9v36h304v-36H17.9z M303.9,50.9h-268c-8.8,0-16-7.2-16-16s7.2-16,16-16h268c8.8,0,16,7.2,16,16 S312.8,50.9,303.9,50.9z"
            />
          </g>
        </g>

        <g>
          <g>
            <path
              className="st0"
              fill={fill}
              d="M-0.1-0.1v70h340v-70H-0.1z M303.9,50.9h-268c-8.8,0-16-7.2-16-16s7.2-16,16-16h268c8.8,0,16,7.2,16,16 S312.8,50.9,303.9,50.9z"
            />
          </g>
        </g>
      </g>
    </svg>
  </>
);
