import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";

import MenuLink from "./MenuLink/MenuLink";
import Logo from "../Logo/Logo";

import { PanelKind } from "../Home/Home";
import "./Menu.scss";


type MenuSpec = {
  name: string;
  panel: PanelKind;
};

interface MenuProps {
  active: boolean;
  goToPanel: (panel: PanelKind) => void;
  scrollToTop: () => void;
}


function Menu({
  active,
  goToPanel,
  scrollToTop
}: MenuProps) {
  const menuSpecs: MenuSpec[] = [
    {
      name: "Kodėl mes?",
      panel: PanelKind.INTRODUCTION
    },
    {
      name: "Paslaugos",
      panel: PanelKind.STAGES
    },
    {
      name: "Apie įmonę",
      panel: PanelKind.ABOUT
    },
    {
      name: "Klientai",
      panel: PanelKind.CLIENTS
    },
    {
      name: "Kontaktai",
      panel: PanelKind.CONTACT
    }
  ];
  const [isActive, setIsActive] = useState(false);
  const menuElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  useEffect(() => {
    if(isActive) {
      gsap.to(menuElement.current, {
        opacity: 1,
        duration: 0.5,
        onStart: () => changeMenuDisplay("block")
      })
    } else {
      gsap.to(menuElement.current, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => changeMenuDisplay("none")
      });
    }
  }, [isActive])


  const triggerGoToPanel = (panel: PanelKind) => {
    setIsActive(false);
    goToPanel(panel);
  }


  const triggerScrollToTop = () => {
    setIsActive(false);
    scrollToTop();
  }


  const changeMenuDisplay = (display: "block" | "none") => {
    if(menuElement && menuElement.current) {
      menuElement.current.style.display = display;
    }
  }


  return (
    <div className="menu" ref={menuElement}>
      <Logo
        fill="white"
        opacity={1}
        zIndex={105}
        triggerScrollToTop={triggerScrollToTop}
      />

      <div className="menu_items-container">
        <div className="menu_items">
          {
            menuSpecs.map((item: MenuSpec, index: number) => <MenuLink key={index} text={item.name} trigger={() => triggerGoToPanel(item.panel)} />)
          }
        </div>
      </div>
    </div>
  );
}

export default Menu;
