import React, { createRef } from "react";

import createPanel from "../../utility/createPanel";
import InlineVideo from "../InlineVideo/InlineVideo";

import { BRAND_WHITE } from "../../utility/constants";

import "./Clients.scss";


const panelRef = createRef<any>();


const Clients = createPanel({
  backgroundColour: BRAND_WHITE,
  children: (
    <div className="clients">
      Kaip partneriai prisijungiame prie NT projektų vystytojų komandų, kai joms kyla poreikis pasitelkti visą vadybinį procesą bei teisinę aplinką išmanantį praktiką iš šalies, turintį vertingos patirties sprendžiant panašias skirtingų užsakovų problemas.
      <br /><br />
      Kita mūsų klientų grupė – tai privatūs asmenys ar įmonės, kuriems vykdant savo veiklą iškyla konkrečių su turimo ar ketinamo įsigyti nekilnojamojo turto valdymu susijusių klausimų. <InlineVideo source="nt-pro-logo-small.mp4" triggerAction={() => panelRef.current.callbackFn()} />
    </div>
  ),
  callback: () => panelRef.current.output(),
  panelRef
});

export default Clients;
