import React, { useState } from "react";

import Home from "./components/Home/Home";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Holding from "./components/Holding/Holding";

import "./App.scss";


function App() {
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

  const openPrivacyPolicy = () => setPrivacyPolicyOpen(true);


  const closePrivacyPolicy = () => setPrivacyPolicyOpen(false);


  return (
    <div className="App">
      {
        process.env.REACT_APP_HOLDING === "true" ? (
          <Holding />
        ) : (
          <>
            <Home openPrivacyPolicy={openPrivacyPolicy} />
            <PrivacyPolicy open={privacyPolicyOpen} triggerClose={closePrivacyPolicy} />
          </>
        )
      }
    </div>
  );
}

export default App;
