import React, { forwardRef, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

import Panel from "../Panel/Panel";
import StageProgress, { StageProgressOrientation } from "./StageProgress/StageProgress";
import { MQ_MOBILE, BRAND_WHITE } from "../../utility/constants";


export interface StagesProps {
  stage: number;
  goToStage?: any;
  children: ReactNode;
}


const Stages = forwardRef<HTMLDivElement, StagesProps>(({
  stage,
  goToStage,
  children
}: StagesProps, ref) => {
  const isMobile = useMediaQuery(MQ_MOBILE);


  return (
    <>
      <Panel backgroundColour={BRAND_WHITE} ref={ref}>
        <>
          {
            !isMobile && (
              <div className="stages_progress-desktop">
                <StageProgress
                  stage={stage}
                  orientation={StageProgressOrientation.PORTRAIT}
                  goToStage={(clickedStage: any) => goToStage(clickedStage)}
                />
              </div>
            )
          }

          {
            isMobile && (
              <div className="stages_progress-mobile">
                <StageProgress
                  stage={stage}
                  orientation={StageProgressOrientation.LANDSCAPE}
                  goToStage={(st: any) => goToStage(st)}
                />
              </div>
            )
          }

          {children}
        </>
      </Panel>
    </>
  );
});

export default Stages;
