import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  RefObject
} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useMediaQuery } from "react-responsive";

import Loader from "../Loader/Loader";
import Menu from "../Menu/Menu";
import MenuButton from "../Menu/MenuButton/MenuButton";
import Logo from "../Logo/Logo";
import Video from "../Video/Video";
import Introduction from "../Introduction/Introduction";
import Stages from "../Stages/Stages";
import StageLand from "../Stages/StageLand/StageLand";
import StageTerritory from "../Stages/StageTerritory/StageTerritory";
import StagePlanning from "../Stages/StagePlanning/StagePlanning";
import StageBuilding from "../Stages/StageBuilding/StageBuilding";
import StageUse from "../Stages/StageUse/StageUse";
import About from "../About/About";
import Clients from "../Clients/Clients";
import Contact from "../Contact/Contact";

import { BRAND_BLACK, MQ_MOBILE_LANDSCAPE } from "../../utility/constants";
import "./Home.scss";
import PanelTitle from "../Panel/PanelTitle/PanelTitle";
import TweenVars = gsap.TweenVars;

interface HomeProps {
  openPrivacyPolicy?: () => void;
}

type PanelAnimationSpec = {
  panel: HTMLDivElement | null,
  from: gsap.TweenValue,
  to: gsap.TweenValue
};

type PanelTriggerSpec = {
  prevPanel: PanelAnimationSpec | null,
  currPanel: PanelAnimationSpec,
  nextPanel: PanelAnimationSpec | null,
  start: number,
  end: number,
  kind: PanelKind
};

type PanelTitleTriggerSpec = {
  start: number,
  end: number,
  from: number,
  to: number,
  toChangeText: "start" | "end",
  textOnFowardScroll: string,
  textOnBackScroll: string
};

type StageTriggerSpec = {
  prevStage: HTMLDivElement | null,
  nextStage: HTMLDivElement | null,
  start: number,
  end: number,
  stage: number
};

export enum PanelKind {
  INTRODUCTION = "introduction",
  STAGES = "stages",
  ABOUT = "about",
  CLIENTS = "clients",
  CONTACT = "contact"
}


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);


function Home({
  openPrivacyPolicy
}: HomeProps) {
  const [panelTitle, setPanelTitle] = useState("");
  const [menuActive, setMenuActive] = useState(false);
  const [stage, setStage] = useState(1);
  const [loaderTrigger, setLoaderTrigger] = useState(false);

  const panelTimelines: RefObject<{[key: string]: gsap.core.Timeline}> = useRef({});
  const stageTimelines: RefObject<gsap.core.Timeline[]> = useRef([]);
  const isMobile = useMediaQuery(MQ_MOBILE_LANDSCAPE);

  const logoElement = createRef<HTMLDivElement>();
  const panelTitleElement = createRef<HTMLDivElement>();
  const introductionPanel = createRef<HTMLDivElement>();
  const stagesPanel = createRef<HTMLDivElement>();
  const stageLandPanel = createRef<HTMLDivElement>();
  const stageTerritoryPanel = createRef<HTMLDivElement>();
  const stagePlanningPanel = createRef<HTMLDivElement>();
  const stageBuildingPanel = createRef<HTMLDivElement>();
  const stageUsePanel = createRef<HTMLDivElement>();
  const aboutPanel = createRef<HTMLDivElement>();
  const clientsPanel = createRef<HTMLDivElement>();
  const contactPanel = createRef<HTMLDivElement>();

  const SCROLL_TRIGGER = ".home_container";
  const SCROLL_EASE = "power1.out";

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    applyAllAnimations();
    setInitialPanelPositions();
    ScrollTrigger.refresh();

    return() => {
      if(panelTimelines && panelTimelines.current) {
        Object.values(panelTimelines.current).forEach(timeline => timeline.kill());
      }

      if(stageTimelines && stageTimelines.current) {
        Object.values(stageTimelines.current).forEach(timeline => timeline.kill());
      }
    }
  }, []);


  const applyAllAnimations = () => {
    const panelTriggers: PanelTriggerSpec[] = [
      {
        prevPanel: null,
        currPanel: {
          panel: introductionPanel.current,
          from: "90vh",
          to: 0
        },
        nextPanel: {
          panel: stagesPanel.current,
          from: "100vh",
          to: "90vh"
        },
        start: 2,
        end: 8,
        kind: PanelKind.INTRODUCTION
      },
      {
        prevPanel: {
          panel: introductionPanel.current,
          from: 0,
          to: "-10vh"
        },
        currPanel: {
          panel: stagesPanel.current,
          from: "90vh",
          to: 0
        },
        nextPanel: null,
        start: 12,
        end: 18,
        kind: PanelKind.STAGES
      },
      {
        prevPanel: null,
        currPanel: {
          panel: aboutPanel.current,
          from: "100vh",
          to: 0
        },
        nextPanel: {
          panel: clientsPanel.current,
          from: "100vh",
          to: "90vh"
        },
        start: 62,
        end: 68,
        kind: PanelKind.ABOUT
      },
      {
        prevPanel: {
          panel: aboutPanel.current,
          from: 0,
          to: "-10vh"
        },
        currPanel: {
          panel: clientsPanel.current,
          from: "90vh",
          to: 0
        },
        nextPanel: {
          panel: contactPanel.current,
          from: "100vh",
          to: "90vh"
        },
        start: 72,
        end: 78,
        kind: PanelKind.CLIENTS
      },
      {
        prevPanel: {
          panel: clientsPanel.current,
          from: 0,
          to: 0
        },
        currPanel: {
          panel: contactPanel.current,
          from: "100vh",
          to: 0
        },
        nextPanel: null,
        start: 82,
        end: 88,
        kind: PanelKind.CONTACT
      }
    ];

    const panelTitleTriggers: PanelTitleTriggerSpec[] = [
      {
        start: 5,
        end: 8,
        from: 0,
        to: 1,
        toChangeText: "start",
        textOnFowardScroll: "Kodėl mes?",
        textOnBackScroll: ""
      },
      {
        start: 12,
        end: 15,
        from: 1,
        to: 0,
        toChangeText: "end",
        textOnFowardScroll: "",
        textOnBackScroll: "Kodėl mes?"
      },
      {
        start: 15,
        end: 18,
        from: 0,
        to: 1,
        toChangeText: "start",
        textOnFowardScroll: "Paslaugos",
        textOnBackScroll: ""
      },
      {
        start: 62,
        end: 65,
        from: 1,
        to: 0,
        toChangeText: "end",
        textOnFowardScroll: "",
        textOnBackScroll: "Paslaugos"
      },
      {
        start: 65,
        end: 68,
        from: 0,
        to: 1,
        toChangeText: "start",
        textOnFowardScroll: "Apie įmonę",
        textOnBackScroll: ""
      },
      {
        start: 72,
        end: 75,
        from: 1,
        to: 0,
        toChangeText: "end",
        textOnFowardScroll: "",
        textOnBackScroll: "Apie įmonę"
      },
      {
        start: 75,
        end: 78,
        from: 0,
        to: 1,
        toChangeText: "start",
        textOnFowardScroll: "Klientai",
        textOnBackScroll: ""
      },
      {
        start: 82,
        end: 85,
        from: 1,
        to: 0,
        toChangeText: "end",
        textOnFowardScroll: "",
        textOnBackScroll: "Klientai"
      },
      {
        start: 85,
        end: 88,
        from: 0,
        to: 1,
        toChangeText: "start",
        textOnFowardScroll: "Kontaktai",
        textOnBackScroll: ""
      }
    ];

    const stageTriggers: StageTriggerSpec[] = [
      {
        prevStage: null,
        nextStage: stageLandPanel.current,
        start: 13,
        end: 15,
        stage: 1
      },
      {
        prevStage: stageLandPanel.current,
        nextStage: stageTerritoryPanel.current,
        start: 23,
        end: 25,
        stage: 2
      },
      {
        prevStage: stageTerritoryPanel.current,
        nextStage: stagePlanningPanel.current,
        start: 33,
        end: 35,
        stage: 3
      },
      {
        prevStage: stagePlanningPanel.current,
        nextStage: stageBuildingPanel.current,
        start: 43,
        end: 45,
        stage: 4
      },
      {
        prevStage: stageBuildingPanel.current,
        nextStage: stageUsePanel.current,
        start: 53,
        end: 55,
        stage: 5
      }
    ];

    applyLogoAnimation();

    for(const trigger of panelTriggers) {
      applyPanelAnimation(trigger);
    }

    for(const trigger of panelTitleTriggers) {
      applyPanelTimelineAnimation(trigger);
    }

    for(const trigger of stageTriggers) {
      applyStageAnimation(trigger);
    }
  }


  const setInitialPanelPositions = () => {
    const panels = [
      stagesPanel,
      aboutPanel,
      clientsPanel,
      contactPanel
    ];

    for(const panel of panels) {
      if(panel && panel.current) {
        panel.current.style.top = "100vh";
      }
    }

    if(introductionPanel && introductionPanel.current) {
      introductionPanel.current.style.top = "90vh";
    }
  }


  const setupPanelTimeline = (startTopPc: number, endTopPc: number) => gsap.timeline({
    scrollTrigger: {
      trigger: SCROLL_TRIGGER,
      start: `top+=${startTopPc}%`,
      end: `top+=${endTopPc}%`,
      immediateRender: false,
      scrub: true
    }
  });


  const setupPanelTitleTimeline = (
    startTopPc: number,
    endTopPc: number,
    toChangeText: "start" | "end",
    textOnForwardScroll: string,
    textOnBackScroll: string
  ) => gsap.timeline({
    scrollTrigger: {
      trigger: SCROLL_TRIGGER,
      start: `top+=${startTopPc}%`,
      end: `top+=${endTopPc}%`,
      immediateRender: false,
      scrub: true,
      onEnter: () => toChangeText === "start" && setPanelTitle(textOnForwardScroll),
      onLeave: () => toChangeText === "end" && setPanelTitle(textOnForwardScroll),
      onEnterBack: () => toChangeText === "end" && setPanelTitle(textOnBackScroll),
      onLeaveBack: () => toChangeText === "start" && setPanelTitle(textOnBackScroll)
    }
  });


  const setupStageTimeline = (
    startTopPc: number,
    endTopPc: number,
    stage: number
  ) => gsap.timeline({
    scrollTrigger: {
      trigger: SCROLL_TRIGGER,
      start: `top+=${startTopPc}%`,
      end: `top+=${endTopPc}%`,
      snap: 1,
      fastScrollEnd: true,
      immediateRender: false,
      onEnter: () => changeStage(stage),
      onLeave: () => changeStage(stage),
      onEnterBack: () => {
        const backStage = stage > 1 ? stage - 1 : stage;
        changeStage(backStage);
      },
      onLeaveBack: () => {
        const backStage = stage > 1 ? stage - 1 : stage;
        changeStage(backStage);
      },
      scrub: true
    }
  });


  const applyLogoAnimation = () => gsap.to(logoElement.current, {
    opacity: 1,
    scrollTrigger: {
      trigger: SCROLL_TRIGGER,
      start: "top+=5%",
      end: "top+=8%",
      scrub: true
    }
  });


  const applyPanelAnimation = (
    {
      prevPanel,
      currPanel,
      nextPanel,
      start,
      end,
      kind
    }: PanelTriggerSpec
  ) => {
    panelTimelines.current![kind] = setupPanelTimeline(start, end);

    if(prevPanel && nextPanel) {
      panelTimelines.current![kind].fromTo(prevPanel.panel, {
          top: prevPanel.from
        },
        {
          top: prevPanel.to,
          ease: SCROLL_EASE
        }
      )
      .fromTo(currPanel!.panel, {
          top: currPanel!.from
        },
        {
          top: currPanel!.to,
          duration: 2,
          ease: SCROLL_EASE
        }
      )
      .fromTo(nextPanel.panel, {
          top: nextPanel.from
        },
        {
          top: nextPanel.to,
          ease: SCROLL_EASE
        }
      );
    } else {
      const target = prevPanel ? prevPanel : nextPanel!;
      panelTimelines.current![kind].fromTo(currPanel!.panel, {
          top: currPanel!.from
        },
        {
          top: currPanel!.to,
          duration: 2,
          ease: SCROLL_EASE
        }
      )
      .fromTo(target.panel, {
          top: target.from
        },
        {
          top: target.to,
          ease: SCROLL_EASE
        }
      );
    }

    panelTimelines.current![kind].addLabel("panel", "+=1%");
  };


  const applyPanelTimelineAnimation = (
    {
      start,
      end,
      from,
      to,
      toChangeText,
      textOnFowardScroll,
      textOnBackScroll
    }: PanelTitleTriggerSpec
  ) => {
    const timeline = setupPanelTitleTimeline(start, end, toChangeText, textOnFowardScroll, textOnBackScroll);

    timeline.fromTo(panelTitleElement.current, {
      opacity: from
    }, {
      opacity: to
    });
  };


  const applyStageAnimation = (
    {
      prevStage,
      nextStage,
      start,
      end,
      stage
    }: StageTriggerSpec
  ) => {
    stageTimelines.current![stage - 1] = setupStageTimeline(start, end, stage);

    if(prevStage && nextStage) {
      stageTimelines.current![stage - 1].to(prevStage, {
        opacity: 0
      })
      .to(nextStage, {
        opacity: 1
      })
      .addLabel("stage", "+=1%");
    } else {
      const target = prevStage ? prevStage : nextStage;
      const finalOpacity = prevStage ? 0 : 1;

      stageTimelines.current![stage - 1].to(target, {
        opacity: finalOpacity
      })
      .addLabel("stage", "+=1%");
    }
  };


  const changeStage = (stage: number) => setStage(stage);


  const goToStage = (stage: number) => gsap.to(window, {
    scrollTo: stageTimelines.current![stage - 1].scrollTrigger!.labelToScroll("stage")
  });


  const goToPanel = (panel: PanelKind) => {
    setMenuActive(false);

    const animVars: TweenVars = {
      scrollTo: panelTimelines.current![panel].scrollTrigger!.labelToScroll("panel")
    };

    if(isMobile) {
      animVars.duration = 0;
      setLoaderTrigger(!loaderTrigger);
    }

    gsap.to(window, animVars);
  }


  const changeMenuActivity = (active: boolean) => setMenuActive(active);


  const triggerPrivacyPolicy = () => {
    if(openPrivacyPolicy) {
      openPrivacyPolicy();
    }
  }


  const scrollToTop = () => {
    setMenuActive(false);

    const animVars: TweenVars = {
      scrollTo: 0
    };

    if(isMobile) {
      animVars.duration = 0;
      setLoaderTrigger(!loaderTrigger);
    }

    gsap.to(window, animVars);
  }


  return (
    <div className="home_container" data-testid="home">
      <Loader reload={loaderTrigger} />

      <MenuButton active={menuActive} menuActive={(active: boolean) => changeMenuActivity(active)} />

      <Logo
        fill={BRAND_BLACK}
        ref={logoElement}
        triggerScrollToTop={scrollToTop}
      />

      <PanelTitle text={panelTitle} ref={panelTitleElement} />

      <Menu
        active={menuActive}
        goToPanel={(panel: PanelKind) => goToPanel(panel)}
        scrollToTop={scrollToTop}
      />

      <Video />

      <Introduction ref={introductionPanel} output={() => goToStage(1)} />

      <Stages
        stage={stage}
        ref={stagesPanel}
        goToStage={(clickedStage: number) => goToStage(clickedStage)}
      >
        <StageLand ref={stageLandPanel} />
        <StageTerritory ref={stageTerritoryPanel} />
        <StagePlanning ref={stagePlanningPanel} />
        <StageBuilding ref={stageBuildingPanel} />
        <StageUse ref={stageUsePanel} />
      </Stages>

      <About ref={aboutPanel} output={() => goToPanel(PanelKind.CLIENTS)} />

      <Clients ref={clientsPanel} output={() => goToPanel(PanelKind.CONTACT)} />

      <Contact ref={contactPanel} output={triggerPrivacyPolicy} />
    </div>
  );
}

export default Home;
