import React, { memo } from "react";

import createStage from "../../../utility/createStage";
import StageTemplate from "../StageTemplate/StageTemplate";


const StageBuilding = createStage(
  <>
    <StageTemplate
      title={
        <>
          IV. Statybų proceso<br />
          vykdymas
        </>
      }
      video="statybu-proceso-vykdymas.mp4"
    >
      <ul>
        <li>Rangos komandos suformavimas</li>
        <li>Rangos sutartys</li>
        <li>Rangos proceso vadyba</li>
        <li>Statybos užbaigimo proceso valdymas</li>
      </ul>
    </StageTemplate>
  </>
);

export default memo(StageBuilding);
