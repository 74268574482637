import React from "react";

import VideoPlayer from "../VideoPlayer/VideoPlayer";

import "./InlineVideo.scss";

interface InlineVideoProps {
  source: string;
  oggSource?: string;
  webmSource?: string;
  triggerAction?: () => void;
}


function InlineVideo({
  source,
  oggSource,
  webmSource,
  triggerAction
}: InlineVideoProps) {
  const attributes: any = {};
  if(oggSource) {
    attributes[oggSource] = oggSource;
  }
  if(webmSource) {
    attributes[webmSource] = webmSource;
  }

  return (
    <div className={`inline-video${triggerAction ? " _trigger" : ""}`} onClick={triggerAction}>
      <div>
        <VideoPlayer source={`video/${source}`} {...attributes} />
      </div>
    </div>
  );
}

export default InlineVideo;
