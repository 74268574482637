const logo = (fill: string) => (
  <>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 300 70"
    >
      <g>
	      <path fill={fill} d="M4.8,6.5h29.7L41,49.9h3.2V6.5h13.6v56.6H29.2l-6.9-43.4h-3.9v43.4H4.8V6.5z" />
        <path fill={fill} d="M77.8,19.9H63.2V6.5h43.9v13.4H92.6v43.2H77.8V19.9z" />
        <path fill={fill} d="M107.9,36.6h25.5v11.8h-25.5V36.6z" />
        <path fill={fill} d="M186.9,23.2c0-5.3-2.1-9.3-6.2-12.2c-4.1-2.9-9.7-4.3-16.8-4.3h-12.2c-6.4,0-10.7-0.1-12.9-0.3c-0.1,0.6-0.1,1.2,0,1.8 l1.4,0.4c1.7,0.4,2.9,1.2,3.7,2.3c0.7,1.1,1.1,2.8,1.1,5.1v35c0,1.8-0.1,3.2-0.2,4.4c-0.2,1.2-0.3,2.1-0.5,2.8 c-0.2,0.7-0.5,1.2-1,1.6c-0.5,0.4-0.9,0.7-1.2,0.8c-0.4,0.1-0.9,0.3-1.6,0.5l-1.5,0.3c-0.1,0.3-0.1,0.6-0.1,1c0,0.4,0,0.7,0.1,0.8 c1.6-0.2,6-0.3,13.3-0.3c7.7,0,12.3,0.1,13.8,0.3c0.1-0.1,0.1-0.4,0.1-0.8c0-0.4,0-0.8-0.1-1l-1.6-0.3c-0.7-0.1-1.3-0.2-1.7-0.4 c-0.4-0.1-0.9-0.4-1.3-0.8c-0.5-0.4-0.9-1-1.1-1.6c-0.2-0.7-0.4-1.6-0.6-2.9c-0.2-1.2-0.3-2.7-0.3-4.5v-8.4c0-0.5,0.2-1,0.5-1.3 c0.3-0.3,0.7-0.5,1.2-0.5h2.6c4,0,7.5-0.5,10.6-1.4c3-0.9,5.5-2.2,7.3-3.9c1.8-1.6,3.2-3.5,4.1-5.6 C186.4,27.9,186.9,25.7,186.9,23.2z M172,28.8c-0.3,1.8-0.9,3-1.6,4.5c-0.7,1.5-1.8,2.7-3,3.6c-1.3,0.9-2.8,1.3-4.4,1.3h-2.1 c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.3V11.7c0-1,0.2-1.6,0.7-2.1c0.5-0.4,1-0.6,1.6-0.6h1.1c1.9,0.1,3.5,0.5,4.8,1.2 c1.3,0.7,2.3,1.8,3.1,3.2c0.7,1.4,1.2,2.4,1.5,4.1c0.3,1.6,0.5,3.5,0.5,5.7C172.6,25.2,172.4,27.1,172,28.8z" />
        <path fill={fill} d="M293.7,22.2c-1.4-3.3-3.4-6.2-5.9-8.6c-2.5-2.5-5.6-4.4-9.3-5.8c-3.7-1.4-7.7-2.2-12-2.2c-4.3,0-8.4,0.8-12.2,2.3 c-3.9,1.5-7.3,3.6-10.2,6.3c-2.9,2.7-5.2,5.9-6.9,9.7c-1.7,3.8-2.6,7.9-2.6,12.3c0,4.3,0.8,8.3,2.5,11.9c1.7,3.6,3.9,6.6,6.7,8.9 c0.6,0.5,1.3,0.9,1.6,1.1c-1.9,0.4-5.2,0.5-8.3-1.1c-5.1-2.7-8.3-10.1-8.8-13.7c-0.1-0.8-0.1-1.3-0.3-1.8c-0.1-0.2-0.4-0.5-0.6-0.5 c-5-1.1-8.6-1.2-11.9-4.6l0.1-0.2c5.9-0.6,9.3-2.2,12.3-4.9c3-2.7,4.5-6,4.5-10.1c0-4.7-2.1-8.3-6.2-10.8 c-4.1-2.4-10.1-3.7-17.7-3.7H197c-6.3,0-10.5-0.1-12.7-0.3c-0.1,0.6-0.1,1.2,0,1.8l1.4,0.4c1.7,0.4,2.9,1.2,3.7,2.3 c0.7,1.1,1.1,2.8,1.1,5.1v35c0,1.7-0.1,3.2-0.3,4.5c-0.2,1.2-0.5,2.2-0.8,2.9c-0.3,0.7-0.7,1.2-1.2,1.6c-0.5,0.4-1,0.7-1.4,0.8 c-0.4,0.1-0.9,0.2-1.5,0.4l-2,0.3c-0.2,0.6-0.2,1.2,0,1.8c1.5-0.2,6.3-0.3,14.4-0.3c7.7,0,12.4,0.1,13.9,0.3c0.2-0.6,0.2-1.2,0-1.8 l-1.7-0.3c-0.8-0.1-1.3-0.3-1.7-0.4c-0.4-0.1-0.8-0.4-1.3-0.8c-0.5-0.4-0.9-0.9-1.1-1.6c-0.2-0.7-0.4-1.6-0.6-2.8 c-0.2-1.2-0.3-2.7-0.3-4.5V38.2c0-0.5,0.2-0.9,0.5-1.3c0.3-0.3,0.7-0.5,1.2-0.5h3.3c0.6,0,1,0.1,1.3,0.4c0.3,0.3,0.5,0.7,0.7,1.4 l2.5,8.7c3.9,12.4,13.6,17.2,23.2,17.1c5-0.1,9.5-1.4,12.8-3.2c1.2,0.6,1.7,1,3.1,1.5c3.5,1.3,7.1,1.9,10.8,1.9 c4.8,0,9.2-0.8,13.2-2.4c4-1.6,7.4-3.8,10.1-6.7c2.7-2.8,4.8-6.2,6.3-10c1.5-3.9,2.2-8,2.2-12.5C295.9,29,295.2,25.5,293.7,22.2z M208.1,33.9h-1.6c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.3v-20c0-1.2,0.2-2,0.6-2.5c0.4-0.5,1.1-0.7,1.9-0.7h1.2 c6.4,0,9.6,3.7,9.6,11.9c0,4.1-0.9,7.4-2.6,9.8C213.8,33.1,211.3,33.9,208.1,33.9z M281.5,39.7c0,6.9-1.7,17.1-8.8,20.3 c-1.4,0.6-2.9,0.8-4.5,0.8c-4.4,0-7.6-0.6-11-4.9c5.3-5,8.7-12.3,6.6-18.8c-1.3-4.1-5.4-5.3-8-3.7c-4,2.5-2.6,7.1,1,9.4 c2.3,1.4,2,5,0.9,7c-0.8,1.4-2,2.8-2.7,3.3c0,0-0.4-0.5-0.6-0.8c-3-4.9-4.8-10.9-5.5-18.2c-0.4-4.7-0.1-9.9,1.2-14.5 c1.2-3.8,3.5-7.3,7-9.4c2.1-1.3,4.5-1.7,6.4-1.6c2.2,0.1,5,0.5,7.8,2.9c2.8,2.4,5,5.6,6.7,9.7c1.7,4.1,2.8,8.6,3.2,13.6 c0.1,1.4,0.1,2.9,0.2,4.6C281.5,39.2,281.5,39.7,281.5,39.7z" />
      </g>
    </svg>
  </>
);

export default logo;
