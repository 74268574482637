import React, { useRef, useEffect } from "react";

import { gsap } from "gsap";

import "./Loader.scss";

interface LoaderProps {
  reload?: boolean;
}


function Loader({
  reload = false
}: LoaderProps) {
  const loaderElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      gsap.to(loaderElement.current, {
        opacity: 0,
        duration: 0.75,
        onComplete: () => removeLoader()
      });
    }, 250)
  }, []);

  useEffect(() => {
    const timeline: gsap.core.Timeline = gsap.timeline({
      onStart: () => displayLoader()
    });

    timeline.to(loaderElement.current, {
      opacity: 1,
      duration: 0
    })
    .to(loaderElement.current, {
      opacity: 0,
      duration: 0.75,
      onComplete: () => removeLoader()
    }, ">0.25");
  }, [reload]);


  const displayLoader = () => {
    if(loaderElement && loaderElement.current) {
      loaderElement.current.style.display = "block";
    }
  }


  const removeLoader = () => {
    if(loaderElement && loaderElement.current) {
      loaderElement.current.style.display = "none";
    }
  }


  return (
    <div className="loader" ref={loaderElement}></div>
  );
}

export default Loader;
