import React from "react";

import "./MenuLink.scss";

export interface MenuLinkProps {
  text: string;
  trigger?: () => void;
}


function MenuLink({
  text,
  trigger
}: MenuLinkProps) {
  return (
    <button className="menu-link" onClick={trigger}>
      {text}
    </button>
  )
}

export default MenuLink;
