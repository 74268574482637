import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

import "./PrivacyPolicy.scss";

interface PrivacyPolicyProps {
  open: boolean;
  triggerClose: Function;
}


function PrivacyPolicy({
  open,
  triggerClose
}: PrivacyPolicyProps) {
  const privacyPolicyPanel = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(open) {
      gsap.to(privacyPolicyPanel.current, {
        top: "20vh",
        duration: 2.5,
        ease: "bounce.out"
      });
    }
  }, [open]);


  const closePrivacyPolicy = () => {
    gsap.to(privacyPolicyPanel.current, {
      top: "100vh",
      duration: 0.5
    });

    triggerClose(false);
  }


  return (
    <div className="privacy-policy" ref={privacyPolicyPanel}>
      <div className="privacy-policy_container">
        <p>PRIVATUMO POLITIKA IR ASMENS DUOMENŲ APSAUGA</p>

        <p>Ši svetainė skirta pristatyti konsultacinės bendrovės UAB “Nekilnojamojo turto profesionalai” veiklą bei teikiamas konsultacines paslaugas. Mes nenaudojame šios erdvės rinkti kokią nors informaciją – nei asmenis identifikuojančių duomenų, nei anoniminių statistinių lankytojų parametrų per įdiegtus slapukus (angl. cookies) ar trečiųjų šalių (pvz., Google Analytics) įskiepius, IŠSKYRUS anoniminius duomenis, susijusius su svetainės naršymo kalbos versijų pasirinkimu: šie duomenys saugomi jūsų naudojamos naršyklės istorijoje, kuriuos galite bet kuriuo metu patys ištrinti.</p>

        <p>Jūsų el. pašto adresas, vardas bei pavardė, kontaktiniai duomenys (jeigu juos nurodote mums siųsdami el. laišką) naudojami ir tvarkomi tik siekiant tinkamai įvertinti ir atsakyti į Jūsų užklausą. Jie jokiais atvejais nėra naudojami rinkodaros tikslais ar perduodami trečiosioms šalims. Jūs patys galite pasirinkti, kokius duomenis apie save pageidaujate pateikti siųsdami mums el. laiškus. Patvirtiname, kad el. paštu Jūsų pačių mums siunčiami, taip pat mūsų socialinių tinklų paskyrų lankytojų duomenys renkami laikantis galiojančių Europos Sąjungos bei Lietuvos Respublikos teisės aktų reikalavimų bei kontroliuojančių institucijų nurodymų.</p>

        <p>Jūsų asmens duomenys, įgyvendinant mūsų teisėtą interesą kaupti įrodymus apie gautas užklausas el. paštu arba per socialinių medijų paskyras, atsakymus į jas, taip pat suteiktas paslaugas ir turėtą kontaktą, priklausomai nuo duomenų rūšies ir aplinkybių, saugomi tol, kol atitinka tikslus, kuriems buvo surinkti. Jūs turite teisę susipažinti su savo asmens duomenimis ir su tuo, kaip jie yra tvarkomi, taip pat galite reikalauti, kad mes ištrintume visus mūsų turimus Jūsų asmeninius duomenis. Jeigu turite kokių nors klausimų, susijusių su asmens duomenų apsauga ar kitais su privatumu susijusiais klausimais, prašome kreiptis el.p. <a href="mailto:info@nt-pro.lt" title="Email">info@nt-pro.lt</a> arba tel. +37068525155</p>

        <div className="privacy-policy_close" onClick={closePrivacyPolicy}>
          &times;
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
