import React, { createRef } from "react";

import createPanel from "../../utility/createPanel";
import InlineVideo from "../InlineVideo/InlineVideo";
import { BRAND_GREY } from "../../utility/constants";

import "./Introduction.scss";


const panelRef = createRef<any>();


const Introduction = createPanel({
  backgroundColour: BRAND_GREY,
  children: (
    <div className="introduction_container">
      <div>
        NT projektų vystymas – kompleksiškas procesas, susidedantis iš keleto etapų. <InlineVideo source="nt-pro-stages.mp4" triggerAction={() => panelRef.current.callbackFn()} /> Jį dar labiau komplikuoja teisinio reglamentavimo aplinka ir greitai besikeičianti situacija rinkoje. Dėl to projektų sėkmę nulemti gali ir visai nuo jo vystytojų nepriklausantys dalykai. Tuomet prireikia žvilgsnio „iš šalies“, gilesnio ir visapusiškesnio konteksto išmanymo, įvairialypės ekspertinės – teisinės, techninės, vadybinės – patirties.
      </div>
    </div>
  ),
  callback: () => panelRef.current.output(),
  panelRef
})

export default Introduction;
