import React, { createRef } from "react";

import createPanel from "../../utility/createPanel";
import InlineVideo from "../InlineVideo/InlineVideo";
import { BRAND_GREY } from "../../utility/constants";

import "./About.scss";


const panelRef = createRef<any>();


const About = createPanel({
  backgroundColour: BRAND_GREY,
  children: (
    <div className="about">
      NT projektų vystymo ir plėtros aplinką (teisinę bazę, procesus bei veikėjus) išmanantis konsultantas, teikiantis kompleksinę ekspertinę teisinę, procesų vadybos, planavimo bei reikiamų sprendinių rengimo pagalbą konkretiems klientų <InlineVideo source="nt-pro-clients.mp4" triggerAction={() => panelRef.current.callbackFn()} /> projektams įgyvendinti ar iškilusioms problemoms spręsti.
    </div>
  ),
  callback: () => panelRef.current.output(),
  panelRef
});

export default About;
