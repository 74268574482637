import React, { ReactNode, VideoHTMLAttributes } from "react";

interface VideoPlayerProps {
  source: string;
  webmSource?: string;
  oggSource?: string;
  className?: string;
  noVideoSupportUI?: ReactNode;
}


function VideoPlayer({
  source,
  webmSource,
  oggSource,
  className,
  noVideoSupportUI
}: VideoPlayerProps) {
  const attributes: VideoHTMLAttributes<HTMLVideoElement> = className ? { className } : {};


  return (
    <video
      preload="metadata"
      autoPlay
      muted
      loop
      playsInline
      disablePictureInPicture
      disableRemotePlayback
      x-webkit-airplay="deny"
      {...attributes}
    >
      <source src={source} type="video/mp4" />
      {
        webmSource && <source src={webmSource} type="video/webm" />
      }
      {
        oggSource && <source src={oggSource} type="video/ogg" />
      }

      {
        noVideoSupportUI ? noVideoSupportUI : (
          <div>
            No video support.
          </div>
        )
      }
    </video>
  );
}

export default VideoPlayer;
