import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";

import { BRAND_WHITE, BRAND_BLACK } from "../../../utility/constants";

import "./StageProgress.scss";

export enum StageProgressOrientation {
  PORTRAIT,
  LANDSCAPE
}

export interface StageProgressProps {
  stage: number;
  orientation: StageProgressOrientation,
  goToStage?: any;
}


function StageProgress({
  stage,
  orientation,
  goToStage
}: StageProgressProps) {
  const [activeStage, setActiveStage] = useState<number | null>(null);
  const stageElements: any = useRef([]);
  const markerElement = useRef<HTMLDivElement>(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setActiveStage(stage - 1);
  }, []);

  useEffect(() => {
    if(stageElements.current) {
      const newStage = stage - 1;

      const element = stageElements.current[newStage];
      const stageDivOffset = orientation === StageProgressOrientation.PORTRAIT ? element.offsetTop : element.offsetLeft;

      const timeline = gsap.timeline();

      const timelineVars: gsap.TimelineVars = orientation === StageProgressOrientation.PORTRAIT
      ? {
          y: stageDivOffset - 8,
          duration: 1
        }
      : {
          x: stageDivOffset,
          duration: 1
        };

      timeline.to(markerElement.current, timelineVars)
      .to(stageElements.current[newStage], {
        color: BRAND_WHITE,
        duration: 1
      }, "<");

      if(activeStage !== null) {
        timeline.to(stageElements.current[activeStage], {
          color: BRAND_BLACK,
          duration: 1
        }, "<");
      }

      setActiveStage(newStage);
    }
  }, [stage])


  return (
    <div className={`stage-progress_container-${orientation === StageProgressOrientation.PORTRAIT ? "portrait" : "landscape"}`}>
      <div className={`stage-progress_marker-${orientation === StageProgressOrientation.PORTRAIT ? "portrait" : "landscape"}`} ref={markerElement}></div>

      <div className={`stage-progress_${orientation === StageProgressOrientation.PORTRAIT ? "portrait" : "landscape"}`}>
        <div ref={ref => stageElements.current[0] = ref} onClick={() => goToStage(1)}>
          I.
        </div>

        <div ref={ref => stageElements.current[1] = ref} onClick={() => goToStage(2)}>
          II.
        </div>

        <div ref={ref => stageElements.current[2] = ref} onClick={() => goToStage(3)}>
          III.
        </div>

        <div ref={ref => stageElements.current[3] = ref} onClick={() => goToStage(4)}>
          IV.
        </div>

        <div ref={ref => stageElements.current[4] = ref} onClick={() => goToStage(5)}>
          V.
        </div>
      </div>
    </div>
  );
}

export default StageProgress;
