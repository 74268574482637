import React, { memo } from "react";

import createStage from "../../../utility/createStage";
import StageTemplate from "../StageTemplate/StageTemplate";


const StagePlanning = createStage(
  <>
    <StageTemplate
      title={
        <>
          III. Projektavimo<br />
          procesas
        </>
      }
      video="projektavimo-procesas.mp4"
    >
      <ul>
        <li>Projektavimo komandos suformavimas</li>
        <li>Projektavimo sutartys</li>
        <li>Projektavimo proceso vadyba</li>
        <li>Projekto derinimas su valstybinėmis institucijomis ir statybą leidžiančių dokumentų gavimas</li>
      </ul>
    </StageTemplate>
  </>
);

export default memo(StagePlanning);
