import React, { memo } from "react";

import StageTemplate from "../StageTemplate/StageTemplate";
import createStage from "../../../utility/createStage";

import "./StageLand.scss";


const StageLand = createStage(
  <>
    <StageTemplate
      title={
        <>
          <div className="stage-land_title-first-line">I. Žemės</div>
          <div className="stage-land_title-last-line">sklypas</div>
        </>
      }
      video="zemes-sklypas.mp4"
    >
      <ul>
        <li>Įsigijimo sandoris</li>
        <li>Valstybinės žemės nuoma</li>
        <li>Susitarimai dėl žemės naudojimo</li>
        <li>Specialiosios žemės naudojimo sąlygos ir kiti apribojimai</li>
      </ul>
    </StageTemplate>
  </>
)

export default memo(StageLand);
