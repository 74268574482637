import React, { memo } from "react";

import createStage from "../../../utility/createStage";
import StageTemplate from "../StageTemplate/StageTemplate";


const StageTerritory = createStage(
  <>
    <StageTemplate
      title={
        <>
          II. Teritorijų<br />
          planavimas
        </>
      }
      video="teritoriju-planavimas.mp4"
    >
      <ul>
        <li>Urbanistinės struktūros planavimas</li>
        <li>Detaliojo plano (ir kitų kompleksinio planavimo dokumentų) rengimas</li>
        <li>Žemės sklypo planų ruošimas</li>
      </ul>
    </StageTemplate>
  </>
);

export default memo(StageTerritory);
