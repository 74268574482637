import React, {
  forwardRef,
  useImperativeHandle,
  RefObject,
  ReactNode
} from "react";

import PanelEdge from "./PanelEdge/PanelEdge";

import "./Panel.scss";


export interface PanelProps {
  backgroundColour: string;
  callback?: Function;
  output?: Function;
  children: ReactNode;
  panelRef?: RefObject<any> | null;
}


const Panel =  forwardRef<HTMLDivElement, PanelProps>(({
  backgroundColour,
  callback,
  output,
  children,
  panelRef
}, ref) => {
  const callbackFn = callback;

  /* eslint-disable react-hooks/exhaustive-deps */
  useImperativeHandle(panelRef, () => ({
    callbackFn,
    output
  }), []);


  return (
    <div
      className="panel_container"
      ref={ref}
      style={
        {
          top: "100vh"
        }
      }
    >
      <div>
        <PanelEdge backgroundColour={backgroundColour} />
      </div>

      <div
        className="panel_content"
        style={
          {
            backgroundColor: backgroundColour
          }
        }
      >
        {children}
      </div>

    </div>
  );
});

export default Panel;
