import React, { memo } from "react";

import { holeCircle, holeBar } from "../../../assets/svgs/panelHoles";

import "./PanelHole.scss";

export enum PanelHoleType {
  CIRCLE,
  BAR
}

export interface PanelHoleProps {
  type: PanelHoleType
  backgroundColour: string;
}


function PanelHole({
  type,
  backgroundColour
}: PanelHoleProps) {
  const mask = (
    <div
      className="panel-hole_mask"
      style={
        {
          backgroundColor: backgroundColour
        }
      }
    >
    </div>
  );


  return (
    <div className="panel-hole">
      {mask}

      {
        type === PanelHoleType.CIRCLE && (
          holeCircle(backgroundColour)
        )
      }

      {
        type === PanelHoleType.BAR && (
          holeBar(backgroundColour)
        )
      }

      {mask}
    </div>
  );
}

export default memo(PanelHole);
