import React, { forwardRef } from "react";

import "./PanelTitle.scss";

export interface PanelTitleProps {
  text: string;
}


const PanelTitle = forwardRef<HTMLDivElement, PanelTitleProps>(({
  text
}: PanelTitleProps, ref) => {
  return (
    <div className="panel-title" ref={ref}>
      {text}
    </div>
  );
});

export default PanelTitle;
