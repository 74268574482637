//noinspection HtmlUnknownTarget

import React, { forwardRef } from "react";

import logo from "../../assets/svgs/logo";
import "./Logo.scss";

interface LogoProps {
  fill: string;
  opacity?: number;
  zIndex?: number;
  triggerScrollToTop: () => void;
}


const Logo = forwardRef<HTMLDivElement, LogoProps>(({
  fill,
  opacity = 0,
  zIndex = 90,
  triggerScrollToTop
}: LogoProps, ref) => {
  const scrollToTop = () => triggerScrollToTop();


  return (
    (
      <div
        className="logo"
        style={
          {
            opacity,
            zIndex
          }
        }
        onClick={() => scrollToTop()}
        ref={ref}
        data-testid="logo"
      >
        {logo(fill)}
      </div>
    )
  );
});

export default Logo;
