//noinspection HtmlUnknownTarget

import React, { memo } from "react";
import { useMediaQuery } from "react-responsive";

import VideoPlayer from "../VideoPlayer/VideoPlayer";

import "./Video.scss";
import { MQ_MOBILE } from "../../utility/constants";


function Video() {
  const isMobile: boolean = useMediaQuery(MQ_MOBILE);


  return (
    <>
      {
        !isMobile && (
          <div className="video_container">
            <div>
              <VideoPlayer className="video_media" source="video/nt-pro-logo.mp4" />
            </div>
          </div>
        )
      }

      {
        isMobile && (
          <div className="video_container">
            <div>
              <VideoPlayer className="video_media" source="video/nt-pro-logo-mobile.mp4" />
            </div>
          </div>
        )
      }
    </>
  );
}

export default memo(Video);
